<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <router-view />
    <!-- Footer -->
    <footer class="footer footer-light footer-static">
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
    <!-- /Footer -->
  </div>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import AppFooter from "@core/layouts/components/AppFooter.vue";

export default {
  components: {
    AppFooter,
  },
  setup() {
    const { contentWidth } = useAppConfig();
    return { contentWidth, AppFooter };
  },
};
</script>
